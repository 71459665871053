import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import MortgageCalculator from "../../components/MortgageCalculator";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const Konditionen = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="So bekommst du die besten Konditionen" showCalc={false} />
            <Article>
                <p>
                    Ein Kredit ist für die meisten keine Kleinigkeit, sondern eine wichtige Entscheidung, die das Leben
                    prägt. Banken vergeben Kredite jeden Tag, für sie sind Kreditgespräche also Routine. Deshalb
                    solltest du top vorbereitet in jedes Gespräch gehen. Dabei möchten wir dir helfen.
                </p>
                <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                    Kreditvergleich anfordern
                </a>
                <hr />

                <h2>Was du wissen solltest</h2>
                <p>
                    Vor Vertragsabschluss einigt man sich im Kreditgespräch mit Banken auf die Konditionen des Kredits.
                    Welche Konditionen am besten zu dir passen, weißt du bestenfalls schon im vorhinein. Lass dich
                    niemals unter Druck setzen, sondern überlege dir gut, was für dich in Frage kommt und was nicht.
                </p>
                <p>
                    Zuerst solltest du dir überlegen, welche Kreditrate, du dir monatlich leisten kannst. Plane dabei
                    immer auch einen kleinen Polster ein, damit du für unvorhergesehene Ausgaben noch etwas übrig hast.
                    Vergiss nicht, dass so ein Polster dich im Fall von möglichen Einkommensverlusten vor einem harten
                    Aufprall schützt.
                </p>
                <p>
                    Kennst du die jeweiligen Vorteile von fixen und variablen Zinssätze? Informiere dich gut darüber,
                    bevor du dich für eine Variante entscheidest. Wir haben dazu einige Informationen für dich
                    zusammengestellt.{" "}
                    <Link to="/artikel/zinsen/" target="_blank" rel="noreferrer noopener">
                        Schau doch mal hinein
                    </Link>
                    .
                </p>
                <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                    Kreditvergleich anfordern
                </a>
                <hr />

                <h2>Nimm nicht gleich das erste Kreditangebot an</h2>
                <p>
                    Es ist wichtig, mehrere Angebote miteinander zu vergleichen, bevor du dich auf einen Kredit
                    einlässt. KOnditionen können so unterschiedlich sein, wie auch der Lifestyle eines jeden menschen
                    unterschiedlich sein kann. Wir haben ein hilfreiches Tool entwickelt, mit dem du verschiedene
                    Konditionen im vorhinein ausprobieren und vergleichen kannst.
                </p>
                <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                    Kreditvergleich anfordern
                </a>
                <hr />

                <h3>Denk an die Zukunft</h3>
                <p>
                    Die Zukunft ist ungewiss, deshalb sollte man auf möglichst vieles vorbereitet sein. Normalerweise
                    ist man an einen Kredit jahrelang, manchmal sogar Jahrzehnte gebunden. Die Konditionen sollten
                    deshalb auch lange Zeit gut zu einem passen. Aber es ist auch immer wieder möglich, zu einem
                    späteren Zeitpunkt den Kredit zu wechseln und somit auch die Konditionen zu verändern. Was dafür
                    nötig ist, erfährst du in unserem Artikel zur{" "}
                    <Link to="/artikel/umschuldung/" target="_blank" rel="noreferrer noopener">
                        Umschuldung
                    </Link>
                    .
                </p>
                <hr />

                <h4>Lass dich gut beraten</h4>
                <p>
                    Zögere nicht, dir vor den Verhandlungsgesprächen einen guten Rat einzuholen. Miracl arbeitet
                    unabhängig von Banken und wir kennen ihre Angebote gut. Wenn du sichergehen willst, dass du den
                    passenden Kredit mit den besten Konditionen findest, wende dich an unsere Finanzexpert*innen. Wir
                    bekommen für die Vermittlung kein Geld von der Bank und verlangen auch keine Provision. Das
                    Erstgespräch ist bei uns immer kostenlos. Einen ersten unkomplizierten Überblick über die mit einem
                    Kredit verbundenen Kosten liefert dir auch unser{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Kreditrechner
                    </Link>
                    .
                </p>
                <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                    Erstgespräch vereinbaren
                </a>
            </Article>
            <MortgageCalculator noTopMargin defaultTerm={35} defaultMortgage={50000} />

            <BreadcrumbList page={"konditionen"}></BreadcrumbList>
            <ArticleStructuredData page={"konditionen"} heading={"So bekommst du die besten Konditionen"} />
        </Layout>
    );
};

export default Konditionen;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: { ns: { in: ["header", "footer", "page.artikel.konditionen"] }, language: { eq: $language } }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
